import React from "react";
import classNames from "classnames";
import { makeStyles } from "@material-ui/core/styles";
import Header from "components/Header/Header.js";
import Footer from "components/Footer/Footer.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import styles from "assets/jss/material-kit-react/views/staticPages.js";
import Parallax from "components/Parallax/Parallax";
import { useTranslation } from "react-i18next";


const dashboardRoutes = [];

const useStyles = makeStyles(styles);


export default function HowItWork(props) {
    const { t } = useTranslation();
  const classes = useStyles();
  const { ...rest } = props;
  return (
    <div>
      <Header
        color="transparent"
        routes={dashboardRoutes}
        rightLinks={<HeaderLinks />}
        fixed
        changeColorOnScroll={{
          height: 400,
          color: "white"
        }}
        {...rest}
      />
      <Parallax small filter image={require("assets/img/header-back.jpg").default} />
      <div className={classNames(classes.main, classes.mainRaised)}>
        <div className={classes.container}>
          <br />
          <h2 className={classes.title}>{t('how_it_works')}</h2>
          <p className={classes.description}>
            <strong>{t('registering5')}</strong>
            </p>
            <p className={classes.description}>
            <ul>
            <li>
                {t('registering1')}
            </li>
            <li>
                {t('registering2')}
            </li>
            <li>
               {t('registering3')}
            </li>
            <li>
               {t('registering4')}
            </li>
            <li>
               {t('registering')}
            </li>
            </ul>
            </p>
            <p className={classes.description}>
            <strong>{t('document')}</strong>
            </p>
            <p className={classes.description}>
            <ul>
            <li>
                {t('document1')}
            </li>
            <li>
                {t('document2')}
                <p className={classes.description}>
                <ul>
                <li>
                    {t('document3')}
                </li>
                <li>
                    {t('document4')}
                </li>
                <li>
                    {t('document5')}
                </li>
                <li>
                    {t('document6')}
                </li>
                <li>
                    {t('document8')}
                </li>
                </ul>
                </p>
            </li>
            </ul>
            </p>
            <p className={classes.description}>
            {t('document9')}
            </p>
            <p className={classes.description}>
            <strong>{t('starting_to_Ride_Deliver')}</strong>
            </p>
            <p className={classes.description}>
            <ul>
            <li>
                {t('starting_to_Ride_Deliver1')}
            </li>
            <li>
                {t('starting_to_Ride_Deliver2')}
            </li>
            <li>
               {t('starting_to_Ride_Deliver3')}
            </li>
            </ul>
            </p>

            <p className={classes.description}>
            <strong>{t('settlements')}</strong>
            </p>
            <p className={classes.description}>
            <ul><strong>{t('settlements1')}</strong></ul>
            </p>
            <p className={classes.description}>
            <ul>
            <li>
                {t('settlements2')}
            </li>
            <li>
                {t('settlements3')}
            </li>
            <li>
               {t('settlements4')}
            </li>
            </ul>
            </p>
            <p className={classes.description}>
            <ul><strong>{t('settlements5')}</strong></ul>
            </p>
            <p className={classes.description}>
            <ul>
            <li>
                {t('settlements6')}
            </li>
            <li>
                {t('settlements7')}
            </li>
            <li>
               {t('settlements8')}
            </li>
            </ul>
            </p>
            <p className={classes.description}>
            <strong>{t('technical_Issues')}</strong>
            </p>
            <p className={classes.description}>
            <ul>
            <li>
                {t('technical_Issues1')}
            </li>
            <li>
                {t('technical_Issues2')}
            </li>
            </ul>
            </p>
          <br />
        </div>
      </div>

      <Footer />
    </div>
  );
}
