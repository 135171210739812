import React,{ useState, useEffect, useContext, useRef } from 'react';
import MaterialTable from 'material-table';
import { useSelector, useDispatch } from "react-redux";
import CircularLoading from "../components/CircularLoading";
import { FirebaseContext } from 'common';
import { useTranslation } from "react-i18next";

export default function Users() {
  const { api } = useContext(FirebaseContext);
  const { t,i18n } = useTranslation();
  const isRTL = i18n.dir();
  const {
    fetchUsersOnce
  } = api;
  const [data, setData] = useState(null);
  const staticusers = useSelector(state => state.usersdata.staticusers);
  const dispatch = useDispatch();
  const loaded = useRef(false);

  useEffect(()=>{
      dispatch(fetchUsersOnce());
  },[dispatch,fetchUsersOnce]);

  useEffect(() => {
    if (staticusers) {
      let arr = [];
      for (let i = 0; i < staticusers.length; i++) {
        let user = staticusers[i];
        if (user.usertype === 'driver' ) {
          arr.push({
            'firstName': user.firstName,
            'lastName': user.lastName,
            'mobile': user.mobile,
            'email': user.email,
            'carType': user.carType,
            'ratedUser': user.ratings && user.ratings.details ? user.ratings.details.length:'0',
            'totalRating': user.ratings && user.ratings.userrating ? user.ratings.userrating:'0',
            'vehicleNumber': user.vehicleNumber,
            'profile_image': user.profile_image ? user.profile_image: null
          });
        }
      }
      setData(arr);
    }
    loaded.current = true;
  }, [staticusers]);

  const columns = [
      { title: t('first_name'), field: 'firstName', cellStyle:{textAlign:'center'} },
      { title: t('last_name'), field: 'lastName', initialEditValue: '',cellStyle:{textAlign:'center'} },
      { title: t('email'), field: 'email', cellStyle:{textAlign:'center'},headerStyle:{textAlign:'center'}},
      { title: t('profile_image'),  field: 'profile_image', render: rowData => rowData.profile_image?<img alt='Profile' src={rowData.profile_image} style={{width: 50,borderRadius:'50%'}}/>:null, editable:'never',cellStyle:{textAlign:'center'}},
      { title: t('vehicle_reg_no'), field: 'vehicleNumber', initialEditValue: '',cellStyle:{textAlign:'center'} },
      { title: t('car_type'), field: 'carType',cellStyle:{textAlign:'center'}},
      { title: 'Number of rating', field: 'ratedUser', cellStyle:{textAlign:'center'}},
      { title: 'Rating', field: 'totalRating', cellStyle:{textAlign:'center'}}
  ];

  return (
    !loaded.current? <CircularLoading/>:
    <MaterialTable
      title='Driver Rating'
      columns={columns}
      data={data}
      style={{direction:isRTL ==='rtl'?'rtl':'ltr'}}
      options={{
        exportButton: true,
        grouping: true,
      }}
    />
  );
}