import React from "react";
import classNames from "classnames";
import { makeStyles } from "@material-ui/core/styles";
import Header from "components/Header/Header.js";
import Footer from "components/Footer/Footer.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import styles from "assets/jss/material-kit-react/views/staticPages.js";
import Parallax from "components/Parallax/Parallax";
import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet";

const dashboardRoutes = [];

const useStyles = makeStyles(styles);


export default function PrivacyPolicy(props) {
  const { t } = useTranslation();
  const classes = useStyles();
  const { ...rest } = props;
  return (
    <div>
      <Helmet>
        <title>Service Terms and Privacy Policy | MotoMoto Technology</title>
        <meta name="description" content="In order to use the services provided using the Moto Moto Platform, you must agree to the terms and conditions and privacy policy in the sections." />
        <meta name="keywords" content="Moto Moto, MotoMoto Technology, Ghana, Privacy Policy,Terms of Service,Service Terms" />
        <link rel="canonical" href="https://motomoto.taxi/privacy-policy" />
      </Helmet>
      <Header
        color="transparent"
        routes={dashboardRoutes}
        rightLinks={<HeaderLinks />}
        fixed
        changeColorOnScroll={{
          height: 400,
          color: "white"
        }}
        {...rest}
      />
      <Parallax small filter image={require("assets/img/header-back.jpg").default} />
      <div className={classNames(classes.main, classes.mainRaised)}>
        <div className={classes.container}>
          <br />
          <h2 className={classes.title}>Privacy Policy</h2>
          <p className={classes.description}>These General Terms set forth the main terms and conditions applying to and governing the usage of the Moto Moto Services.</p>
          <p className={classes.description}>In order to use the services provided using the Moto Moto Platform, you must agree to the terms and conditions that are set forth below.</p>
          <p className={classes.description} style={{ fontWeight: 'bold',color:'black'}}>DEFINITIONS</p>
          <p className={classes.description}>1.1. MotoMoto Technology (also referred to as "we", "our" or "us" or “Moto Moto ”) – A Company incorporated and registered under the laws of the Republic of Ghana.</p>
          <p className={classes.description}>1.2. Moto Moto Services – services that Moto Moto or Moto Moto and partners provide, including provision and maintenance of Moto Moto App, Moto Moto Platform, In-app Payment, customer support, communication between the Rider and the Client and other similar services.</p>
          <p className={classes.description}>1.3. Moto Moto /Rider App – smartphone or web application for Riders and Clients to request and receive Delivery Services.</p>
          <p className={classes.description}>1.4. Moto Moto /Rider Platform – technology connecting Clients with Riders to help them deliver around cities more efficiently.</p>
          <p className={classes.description}>1.5. Client – a person requesting Delivery Services by using Moto Moto Platform.</p>
          <p className={classes.description}>1.6. Rider (also referred to as “you”) – the person providing Delivery Services via the Moto Moto /Rider Platform. Each Rider will get a personal Moto Moto Rider Account to use Moto Moto Rider App and Moto Moto Platform.</p>
          <p className={classes.description}>1.7. Agreement – any agreement between Rider, Moto Moto, or Moto Moto and partners regarding the use of Moto Moto Services. The Agreement consists of these General Terms, the Riders guidelines, and other additional terms and conditions or documents referred to herein or agreed in the future between Rider and Moto Moto or Moto Moto and partners.</p>
          <p className={classes.description}>1.8. Fare – the fee a Client is obliged to pay a Rider for the provision of the Delivery Services.</p>
          <p className={classes.description}>1.9. Moto Moto Fee – the fee that Rider is obliged to pay to Moto Moto or Moto Moto and partners for using the Moto Moto Rider Platform.</p>
          <p className={classes.description}>1.10. In-app Payment – carrier billing, Mobile wallet, and other payment methods used by the Client via the Moto Moto App to pay for the Delivery Services.</p>
          <p className={classes.description}>1.11. Moto Rider Account – access to a website containing information and documents regarding usage of the Moto Moto Services in course of the provision of Delivery Services, including accounting documentation. The rider may access the Moto Moto Rider Account at <a>https://motomoto.taxi</a> </p>
          <p className={classes.description}>1.12. Delivery Services –Delivery Service a Rider is providing to Clients whose request Rider has accepted through the Moto Moto App.</p>
          <p className={classes.description} style={{ fontWeight: 'bold',color:'black'}}>ENTRY INTO AGREEMENT</p>
          <p className={classes.description}>2.1. Prior to using the Moto Moto Services, you must sign up by providing the requested information in the signup application on the website and uploading necessary documentation as required by us or Moto Moto and partners. You may sign up either as a legal or a natural person.</p>
          <p className={classes.description}>Upon successful completion of the signup application, we will provide you with a personal account accessible via a username and password. By clicking the “Sign up" button located at the end of the signup application, you represent and warrants that:</p>
          <p className={classes.description}>pursuant to valid legal acts, you are entitled to enter into an agreement with us or Moto Moto and partners to use the Moto Moto Platform for providing the Delivery Service; you have carefully studied, fully understands, and agrees to be bound by these General Terms, including all obligations that arise as provided herein and from Agreement; all the information you have presented to us or Moto Moto and partners is accurate, correct and complete;</p>
          <p className={classes.description}>you will keep Moto Moto Account accurate and profile information updated at all times;</p>
          <p className={classes.description}>you will not authorize other persons to use your Moto Moto Account nor transfer or assign it to any other person;</p>
          <p className={classes.description}>you will not use the Moto Moto Services for unauthorized or unlawful purposes and impair the proper operation of the Moto Moto Services;</p>
          <p className={classes.description}>at all times, you fully comply with all laws and regulations applicable in the town and city you are providing Delivery Services in, including (but not limited to) laws regulating Client Delivery Services;</p>
          <p className={classes.description}>2.2. You are obliged to provide your bank/ settlement requisites in course of filling in the
payment details upon registration. In case you are a legal person, you must insert the bank
account/ mobile money account of the company. We or Moto Moto and partners are
transferring In-app Payment fees to the bank account/ mobile money account that you have
provided. We and Moto Moto and partners are not liable for any incorrect money transactions in
case you have provided the wrong bank/ mobile money account requisites.</p>
          <p className={classes.description}>2.3. After submitting the signup application, you will receive an e-mail with additional conditions
that must be met in order to use Moto Moto Services. These conditions may include providing a
valid driving license, satisfactory technical state of the vehicle, completion of a training course,
owning a GPS-supporting mobile device, and other conditions as described in the pertinent email. The failure to comply with the provided requirements and conditions may result in
termination of the Agreement and right to use the Moto Moto Services.</p>
          <p className={classes.description}>2.4. You agree that in specific cities or countries we may assign any of our obligations arising
from the General Terms or Agreement to Moto Moto and partners. This includes, among else,
assigning the rights and obligations regarding reviewing documents related to signup
applications, training, collection of Moto Moto Fees, forwarding you the fees due, mediating Inapp Payment, licensing the Moto Moto App, etc.</p>
          <p className={classes.description}>2.6. Registering the account as a legal person (i.e. a company). You are considered to be a legal
person if the recipient of the fees is marked as a legal person in payment details (as accessible
in the Moto Moto Account). In such case, the indicated legal person is considered to be the
provider of Delivery Services and a party to these General Terms, Agreement, and any further
agreements. Only the specific natural person indicated in the signup process may factually
provide the Delivery Services. Such a natural person may use the account of the Rider only if
he/she has read and agrees to be bounded by these General Terms and any further
documentation that is part of the Agreement. THE LEGAL PERSON IN THE PAYMENT DETAILS
AND THE NATURAL PERSON FACTUALLY PROVIDING THE DELIVERY SERVICES UNDER MOTO
MOTO ACCOUNT SHALL REMAIN JOINTLY AND SEVERALLY LIABLE FOR ANY INFRINGEMENT
OF THE GENERAL TERMS AND AGREEMENT CONDUCTED BY THE RIDER.</p>
          <p className={classes.description}>2.7. Registering Moto Moto Account as a fleet company. Upon concluding a separate
agreement, a fleet company may itself register accounts to its employees and/or service
providers. In such case, the fleet company shall be required to ensure that its employees and/or
service providers conform to the requirements of General Terms, Agreement, and any further
agreements and agrees to act in accordance and be bound with its conditions and obligations.
The fleet company and its employees and/or service providers shall remain jointly and severally
liable for any infringement conducted by such employees and/or service providers.</p>
<p className={classes.description} style={{ fontWeight: 'bold',color:'black'}}>RIGHT TO USE MOTO MOTO APP AND MOTO MOTO ACCOUNT</p>
          <p className={classes.description}>3.1. License to use the Moto Moto App and the Moto Moto Account. We hereby grant you a
license to use the Moto Moto App and the Moto Moto Account. The license does not grant you
the right to sublicense or transfer any rights to third persons. Regardless of the above and if so
agreed separately, fleet companies may sub-license the Moto Moto App and the Moto Moto
Account to the members of its fleet.</p>
          <p className={classes.description}>3.2. In course of using the Moto Moto App and/or Moto Moto Account you may not:
decompile, reverse engineer, or otherwise attempt to obtain the source code of the Moto Moto
App, the Moto Moto Account, or other software of Moto Moto ;
modify the Moto Moto App or the Moto Moto Account in any manner or form or to use modified
versions of the Moto Moto App or Moto Moto Account; transmit files that contain viruses,
corrupted files, or any other programs that may damage or adversely affect the operations on
Moto Moto Platform; attempt to gain unauthorized access to the Moto Moto App, Moto Moto
Account or any other Moto Moto Services.</p>
          <p className={classes.description}>3.3. The License granted herein revokes automatically and simultaneously with the termination
of the Agreement. After termination of the Agreement, you must immediately stop using the
Moto Moto App and the Moto Moto Account, and we or Moto Moto and partners are entitled to
block and delete the Rider account without prior notice.</p>
          <p className={classes.description}>3.4. Using tags and labels of Moto Moto. Additionally, we or Moto Moto and partners may give
you tags, labels, stickers, or other signs that refer to the Moto Moto brand or otherwise indicate
you are using the Moto Moto Platform. We grant you a non-exclusive, non-sublicensable, nontransferable license to use such signs and only for the purpose of indicating you are providing
Delivery Services via the Moto Moto Platform. After termination of the Agreement, you must
immediately remove and discard any signs that refer to the Moto Moto brand.</p>
          <p className={classes.description}>3.5. All copyrights and trademarks, including source code, databases, logos, and visual designs
are owned by Moto Moto and protected by copyright, trademark, and/or trade secret laws and
international treaty provisions. By using the Moto Moto Platform or any other Moto Moto
Services you do not acquire any rights of ownership to any intellectual property.</p>
<p className={classes.description} style={{ fontWeight: 'bold',color:'black'}}>PROVIDING THE DELIVERY SERVICES</p>
          <p className={classes.description}>4.1. The Rider’s Obligations. You hereby guarantee to provide Delivery Services in accordance
with the General Terms, Agreement as well as laws and regulations applicable in the state
where you are providing Delivery Services. Please note that you are fully liable for any violation
of any local laws and regulations as may arise from providing Delivery Services.</p>
          <p className={classes.description}>4.2. You must have all licenses (including a valid Rider’s license), permits, bike insurance,
liability insurance (if applicable), registrations, certifications, and other documentation that are
required in the applicable jurisdiction for providing the Delivery Services. It is your obligation to
maintain the validity of all aforementioned documentation. Moto Moto and partners reserve the
right to require you to present evidence and submit for review all the necessary licenses,
permits, approvals, authority, registrations, and certifications.</p>
          <p className={classes.description}>4.3. You must provide the Delivery Services in a professional manner in accordance with the
business ethics applicable to providing such services and endeavor to perform the Client's
request in the best interest of the Client. Among else, you (i) must take the route least costly for
the Client, unless the Client explicitly requests otherwise; (ii) may not make any unauthorized
stops; (iii) may not have any other Deliveries in the vehicle other than the Client; and (iv) must
adhere to any applicable traffic acts and regulations, i.e must not conduct any actions that may </p>
          <p className={classes.description}>4.4. You retain the sole right to determine when you are providing the Delivery Services. You
shall accept, decline, or ignore Delivery Services requests made by Clients of your own
choosing.</p>
          <p className={classes.description}>4.5. Costs you incur while providing the Delivery Services. You are obliged to provide and
maintain all equipment and means that are necessary to perform the Delivery Services at your
own expense, including a bike, smartphone, etc. You are also responsible for paying all costs
you incur in the course of performing the Delivery Services including, but not limited to, fuel,
mobile data plan costs, duty fees, amortization of the vehicle, insurance, relevant corporate or
payroll taxes, etc. Please bear in mind that using the Moto Moto App may bring about the
consummation of a large amount of data on your mobile data plan. Thus, we suggest you to
subscribe a data plan with unlimited or very high data usage capacity</p>
          <p className={classes.description}>4.6. Fares. You are entitled to charge a fare for each instance you have accepted a Client on the
Moto Moto Platform and completed the Delivery Service as requested (i.e. Fare). The Fare is
calculated based on a default base fare, the distance of the specific journey as determined by
the GPS-based device, and the duration of the specific travel. The default base fare may
fluctuate based on the local market situation. You may negotiate the Fare by sending us or
Moto Moto and partners a pertinent request that has been either signed digitally or by hand.
Additionally, you shall always have the right to charge the Client less than the Fare indicated by
the Moto Moto App. However, charging the Client less than the Moto Moto App indicates does
not decrease the Moto Moto Fee.</p>
          <p className={classes.description}>4.7. Fare Review. If you find that there has been an error in the calculation of the Fare and wish
to make corrections in the calculation of the Fare, you must contact Moto Moto. If a petition has
not been submitted to Moto Moto, then Moto Moto and partners shall not recalculate the Fare
or reimburse you for an error made in the calculation of the Fare.</p>
          <p className={classes.description}>4.8. Fare Adjustment. Moto Moto and partners may adjust the Fare for a particular order
completed if we or Moto Moto and partners detect a violation (such as taking a longer route or
not stopping the fare meter of the Moto Moto App after the Delivery Services have been
completed) or in case a technical error affecting the final fare is identified. Moto Moto and
partners may also reduce or cancel the fare in case we have reasonable cause to suggest a
fraud or a complaint by the Client indicates a violation by you. Moto Moto and partners will only
exercise its right to reduce or cancel the fare in a reasonable and justified manner.</p>
          <p className={classes.description}>4.9. Clients may pay the fare for the Delivery Services either directly to you or via the In-app
Payment. In case the Client pays the Fare directly, it is your obligation to collect the Fare. In
case the Client fails or refuses to pay, Moto Moto and partners will send a notice of debt to the
Client on behalf of you. Such authorization derives from the mandate of paying agents given to
Moto Moto and partners and does not entail that Moto Moto and partners have an obligation to
compensate the Fare not paid by the Client. If the Clients do not agree to pay the Fare for the
provision of Delivery Service, the Fare will be paid by the Client who has ordered the provision of
Delivery Service. If the Client justifiably refuses to pay the Fare on the account that your
information stated in the Moto Moto App is incorrect, then Moto Moto and partners will not
reimburse you for such expenses.</p>
          <p className={classes.description}>4.10. Receipts. After each successful provision of Delivery Services, Moto Moto shall create and
forward a receipt to the Client consisting of the following information: the company’s business
name, the first name, and surname of the Rider, a photo of the Rider, service license number (if
applicable), the Vehicle Model, the registration number of the vehicle, the date-, the time-, the
start and end locations-, the duration and length-, the Fare and the Fare paid for the provision of
the Delivery Services. The receipt of each provision of Delivery Services is available to you via
the Moto Moto Account.</p>
          <p className={classes.description}>4.11. If in the course of the provision of the Delivery Services, a Client or its co-Clients
negligently damage the vehicle or its furnishing (among else, by blemishing or staining the
vehicle or causing the vehicle to stink), you shall have the right to request the Client to pay for
the damage caused. If the Client does not consent to paying the penalty and/or compensating
the damage, you must notify us or Moto Moto and partners and we will then try to collect the
penalty and/or relevant costs on your behalf from the Client. However, bear in mind that we or
Moto Moto and partners are not taking any liability for direct or indirect damages in relation to
cleaning or maintenance of the vehicle caused due to the delivery.</p>
          <p className={classes.description}>4.12. Your tax obligations. You hereby acknowledge that you are obliged to fully comply with all
tax obligations that arise to you from the applicable laws in relation to providing the Delivery
Services, including (i) paying income tax, social security tax, or any other tax applicable; and (ii)
fulfilling all employee and tax registration obligations for calculations in regard to accounting
and transfers to applicable State authorities as required by the applicable law. In case the Tax
authority will submit a valid application to us to provide information regarding the activities of
you, we or Moto Moto and partners may make available to the Tax authority the information
regarding the activities of you to the extent set forth in valid legal acts. Additionally, it is your
obligation to adhere to all applicable tax regulations that may apply in connection with the
provision of Delivery Services. You hereby agree to compensate Moto Moto and partners all
state fees, claims, payments, fines, or other tax obligations that Moto Moto and partners will
incur in connection with the obligations arising from applicable tax regulations not having been
met by you (including paying the income tax and social tax).</p>
          <p className={classes.description}>4.13. The Rider’s authorization to issue invoices. Moto Moto and partners have a right to issue
an invoice on your behalf to the Client in order to compensate you any Fares, contractual
penalties, or other fees that Moto Moto and partners mediate to you. The invoice will be made
available to you via the Moto Moto Account.</p>
<p className={classes.description} style={{ fontWeight: 'bold',color:'black'}}>MOTO MOTO FEES</p>
          <p className={classes.description}>5.1. In order to use the Moto Moto Services, you are obliged to pay a fee (i.e. the Moto Moto
Fee). The Moto Moto Fee is paid based on the Fare of each Delivery Service order that you have
completed. The amount of the Moto Moto Fee is made available to you via e-mail, Moto Moto
App, Moto Moto Account, or other pertinent means. Please acknowledge that the Moto Moto
Fee may change from time to time. We or Moto Moto and partners shall send you a prior
notification of each such change.</p>
          <p className={classes.description}>5.2. You must pay the Moto Moto Fee and any other fees due to us and Moto Moto and partners
for the previous month at the latest by the 15th date of the following month or a fee
accumulation of not more than GHS100.00. Upon delay with payment of the Moto Moto Fee.
Your account may be suspended if you default. You are obliged to cover all costs incurred by us </p>
<p className={classes.description} style={{ fontWeight: 'bold',color:'black'}}>IN-APP PAYMENTS</p>
          <p className={classes.description}>6.1. We or Moto Moto and partners may enable Clients to pay for the Delivery Service via mobile
money, carrier billing, and other payment methods directly in the Moto Moto App (i.e. In-app
Payment). You hereby authorize us or Moto Moto and partners to act as your limited
commercial agent solely for the purpose of collecting, on your behalf, the Fares or other fees
paid by the Client via In-app Payment. Any payment obligation made by the Client via the In-app
Payment shall be considered fulfilled as of the time that the payment has been made.</p>
          <p className={classes.description}>6.2. You may not refuse payment by the Client via the In-app Payment, or influence the Client
against the use of the In-app Payment. In case you refuse to accept an In-app Payment without
just cause, we or Moto Moto and partners shall be entitled to charge you a contractual penalty
in the amount of to be specified for every refusal and/or block your right to use the Moto Moto
Rider Services in case of repetitive behavior.</p>
          <p className={classes.description}>6.3. Moto Moto and partners reserve the right to distribute promo codes to riders at our
discretion on a per promotional basis. You are required to accept the use of promo code only
when the rider applies the code in-app to delivery using mobile money payment. Promo codes
may not be applied to cash-paid delivery. If the use of promo codes is suspected as being
fraudulent, illegal, used by a Rider in conflict with our Terms and Conditions relating to promo
code use, then the promo code may be canceled and the outstanding amount will not be
reimbursed by Moto Moto to the Rider.</p>
          <p className={classes.description}>6.4. You are entitled to review In-app Payment reports in the Moto Moto Rider Account or App.
The reports will show the amounts of the In-app Payments brokered in the previous week as
well as the withheld amounts of the Moto Moto Rider Fee. You must notify us or Moto Moto and
partners of any important circumstances which may affect our obligations to collect and
distribute the Fares paid via In-app Payment.</p>
          <p className={classes.description}>6.5. We and Moto Moto and partners are not obliged to pay you the Fare due from the Client if
the In-app Payment failed because Client’s Money Money Account or other payment is canceled
or is unsuccessful for other reasons. In such a case, we will help you in requesting the Fare due
from the Client and shall transmit it to you once the Client has made the requested payment.</p>
          <p className={classes.description}>6.6. Before providing Delivery Services, you must verify that the service is being actually
provided to the right Client or the Client has expressly confirmed he/she allows other Clients to
ride under Client’s account. If you make a mistake in identifying the Client, and the In-app
Payment is charged to a person, who has not been provided or has not approved the Delivery
Services for other Clients, then we or Moto Moto and partners shall reimburse the person for the
Fare. In such case, you are not entitled to receive the Fare from us or Moto Moto and partners.
Additionally, for every wrongfully applied In-app Payment, we and Moto Moto and partners shall
be entitled to charge you a contractual penalty up to be specified later.</p>
          <p className={classes.description}>6.7. Please note that we or Moto Moto and partners will set off any Fares paid via In-app
Payment against the amounts that you are obliged to pay to us or Moto Moto and partners (i.e.
Moto Moto Fees and contractual penalties). We and Moto Moto and partners reserve the right </p>
          <p className={classes.description}>6.8. If we or Moto Moto and partners are not able to pay the Fees to you due to you, not
including your bank account/ mobile money details, Wallet Account in your Rider´s account, or if
the bank account/ mobile money details have been noted incorrectly, then we or Moto Moto and
partners will hold such payments for 180 days. If you do not notify us or Moto Moto and
partners of the correct bank account/ mobile money account details within 180 days from the
date that the right to claim such payments has been established, your claim regarding the
payment of the Fare not transferred to you shall expire.</p>
<p className={classes.description} style={{ fontWeight: 'bold',color:'black'}}>CUSTOMER SUPPORT</p>
          <p className={classes.description}>8.1. In order to guarantee high-quality service and provide additional reassurance to Clients, you
hereby acknowledge that the Clients may provide you a rating and leave feedback regarding the
quality of the Delivery Services that you have provided. Your average rating will be linked to your
rider’s account and will be available to Clients at Moto Moto App. If we find out the rating or
comment is not given in good faith, this rating or comment may not be projected in the
calculations of your rating.</p>
          <p className={classes.description}>8.2. In addition to the rating, we measure your level of activity and provide you with an activity
score, which is based on your activity regarding accepting, declining, not responding, and
completing Delivery Service requests.</p>
          <p className={classes.description}>8.3. In order to provide reliable services to Clients, we or Moto Moto and partners may
determine a minimum average rating and a minimum activity score that riders must establish
and maintain. If, after a pertinent notification from us or Moto Moto and partners, you do not
increase your average rating or activity score to a minimum level within the prescribed time
period, your rider’s account will be automatically suspended either temporarily or permanently.
We may reverse the suspension of your account if it is merited by any external circumstances or
it is detected that the suspension was caused by a system error or false ratings.</p>
<p className={classes.description} style={{ fontWeight: 'bold',color:'black'}}>MARKET OVERVIEWS AND CAMPAIGNS</p>
          <p className={classes.description}>9.1. Market overviews. We or Moto Moto and partners may send you, via the Moto Moto App,
Moto Moto Account, SMS, e-mail, or other means, market overviews, in order to increase your
awareness regarding when the demand by the Clients is highest. Such market overviews are
merely recommendatory and do not constitute any obligations for you. As the market overview
estimations are based on previous statistics, we and Moto Moto and partners cannot give any
guarantees that the actual market situation will correspond to the estimations provided in the </p>
          <p className={classes.description}>9.2. Campaigns promising minimum income. We and Moto Moto and partners may also provide
campaigns, whereby we will guarantee a minimum income if you provide Delivery Services
within a specified timeframe. If the specified minimum is not reached by you, we or Moto Moto
and partners shall compensate for the gap. The specific requirements and conditions will be
sent via the Moto Moto App, Moto Moto Account, SMS, e-mail, or other means. We and Moto
Moto and partners have full discretion in deciding if, when, and to which Riders we provide such
campaigns. If we or Moto Moto and partners have reasonable cause to suspect any fraudulent
activity by you, we may withhold your Fare until the suspicion of fraud has been cleared.</p>
          <p className={classes.description}>9.3. Campaigns for Clients. We and Moto Moto and partners may also occasionally arrange
various campaigns for Clients in order to promote the Moto Moto Platform. If the Fare paid by
the Clients is reduced as part of such a campaign, we or Moto Moto and partners shall pay you
compensation, amounting to the monetary value of the benefit offered to the Clients. We and
Moto Moto and partners may set off the marketing compensation against the Moto Moto Fee.</p>
<p className={classes.description} style={{ fontWeight: 'bold',color:'black'}}>RELATIONSHIP BETWEEN YOU, US, AND THE CLIENTS</p>
          <p className={classes.description}>10.1. You hereby acknowledge and agree that we or Moto Moto and partners provide an
information society service and do not provide Delivery Services By providing the Moto Moto
Platform and Moto Moto Services, we act as a marketplace connecting Clients with Riders to
help them deliver around cities more efficiently. You acknowledge that you are providing the
Delivery Services on the basis of a contract for deliveries of Clients and that you provide the
Delivery Services either independently or via a company as an economic and professional
activity.</p>
          <p className={classes.description}>10.2. You acknowledge that no employment agreement nor an employment relationship has
been or will be established between you and us or Moto Moto and partners. You also
acknowledge that no joint venture or partnership exists between you and us or Moto Moto and
partners. You may not act as an employee, agent, or representative of us or Moto Moto and
partners nor bind any contract on behalf of us. If due to the implication of mandatory laws or
otherwise, you shall be deemed an employee of us or Moto Moto and partners, you hereby
agree to waive any claims against us that may arise as a result of such implied employment
relationship</p>
          <p className={classes.description}>10.3. You may not transfer your rights and obligations deriving from the General Terms or
Agreement to any third party.</p>
<p className={classes.description} style={{ fontWeight: 'bold',color:'black'}}>PROCESSING OF PERSONAL DATA</p>
          <p className={classes.description}>Your personal data will be processed in accordance with the Privacy Notice.</p>
          <p className={classes.description} style={{ fontWeight: 'bold',color:'black'}}>LIABILITY</p>
          <p className={classes.description}>12.1. The Moto Moto Platform is provided on an "as is" and “as available” basis. We and Moto
Moto and partners do not represent, warrant, or guarantee that access to the Moto Moto Platform will be uninterrupted or error-free. As the usage of the Moto Moto Platform for
requesting Delivery Services depends on the behavior of Clients, we or Moto Moto and partners
do not guarantee that your usage of the Moto Moto Platform will result in any Delivery Service
requests.</p>
          <p className={classes.description}>12.2. To the maximum extent permitted under the applicable law, we, Moto Moto and partners,
representatives, directors, and employees are not liable for any loss or damage that you may
incur as a result of using the Moto Moto Services, including but not limited to: any direct or
indirect property damage or monetary loss; loss of profit or anticipated savings; loss of
business, contracts, contacts, goodwill, reputation and any loss that may arise from interruption
of the business; loss or inaccuracy of data; and any other type of loss or damage.</p>
          <p className={classes.description}>12.3. You shall have the right to claim for damages only if we or Moto Moto and partners have
deliberately violated the General Terms of Agreement.</p>
          <p className={classes.description}>12.4. We and Moto Moto and partners shall not be liable for the actions or non-actions due to
the deliveries and shall not be liable for any loss or damage that may incur to you or your vehicle
as a result of actions or non-actions of the deliveries.</p>
          <p className={classes.description}>12.5. You shall be fully liable for breach of the General Terms, Agreement, or any other
applicable laws or regulations and must stop and remedy such breach immediately after receipt
of a respective demand from us or Moto Moto and partners or any state authority. You shall
indemnify us and Moto Moto and partners for any direct and/or indirect loss and/or damage,
loss of profits, expense, penalty, fine that we or Moto Moto and partners may occur in
connection with your breach of the General Terms, Agreement, and laws and regulations. If
Client presents any claims against us or Moto Moto and partners in connection with your
provision of Delivery Services, then you shall compensate such damage to us or Moto Moto and
partners in full within 10 days as of your receipt of the respective request from us or Moto Moto
and partners. In case we or Moto Moto and partners are entitled to present any claims against
you, then you shall compensate us or Moto Moto and partners any legal costs related to the
evaluation of the damages and submission of claims relating to compensation for such
damage.</p>
<p className={classes.description} style={{ fontWeight: 'bold',color:'black'}}>TERM AND TERMINATION</p>
          <p className={classes.description}>13.1. The conditions expressly specified in these General Terms shall enter into force as of
submitting the signup application. Agreements and other terms shall enter into force once the
specific document or message has been made available to you and you commence or continue
providing Delivery Services on Moto Moto Platform.</p>
          <p className={classes.description}>13.2. You may terminate the Agreement at any time by notifying Moto Moto or Moto Moto and
partners at least 7 (seven) days in advance after which your right to use the Moto Moto
Platform and Moto Moto Services shall terminate. Moto Moto or Moto Moto and partners may
terminate the Agreement at any time and for any reason at the sole discretion of us by notifying
you at least 3 (three) days in advance.</p>
          <p className={classes.description}>13.3. Moto Moto or Moto Moto and partners are entitled to immediately terminate the
Agreement and block your access to Moto Moto Platform without giving any advance notice in
case you breach the General Terms or Agreement, any applicable laws or regulations, disparage
Moto Moto or Moto Moto and partners or cause harm to Moto Moto’s brand, reputation or
business as determined by Moto Moto or Moto Moto and partners in our sole discretion. In the
aforementioned cases, we may, at its own discretion, prohibit you from registering a new Driver
account.</p>
          <p className={classes.description}>13.4. We may also immediately block your access to the Moto Moto Platform and to the Moto
Moto Account for the period of the investigation if we suspect an infringement of the
Agreement or fraudulent activity from your behalf. The block of access will be removed once
the investigation disproves such suspicions.</p>
          <p className={classes.description}>13.5. We are aiming to provide the highest quality service to all Clients therefore we are
monitoring the activity of Riders on Moto Moto Platform. If you fail to meet the minimal service
requirements, such as the minimal rating and activity score, we are entitled to immediately
terminate the Agreement without giving any advance notice.</p>
<p className={classes.description} style={{ fontWeight: 'bold',color:'black'}}>AMENDMENTS</p>
          <p className={classes.description}>The Company reserves the right to modify, vary and change the Terms of Use or its policies
relating to the Service at any time as it deems fit. Such modifications, variations, and or
changes to the Terms of Use or its policies relating to the Service shall be effective upon the
posting of an updated version at<a>https://motomoto.taxi</a> . You agree that it shall be your
responsibility to review the Terms of Use regularly and also the Terms of Use applicable to any
country where you use the Service which may differ from the country where you registered for
the Application (the “Alternate Country”) whereupon the continued use of the Service after any
such changes, whether or not reviewed by you, shall constitute your consent and acceptance to
such changes. You further agree that usage of the Service in the Alternate Country shall be
subject to the Terms of Use prevailing for the Alternate Country.</p>
<p className={classes.description} style={{ fontWeight: 'bold',color:'black'}}>APPLICABLE LAW AND COURT JURISDICTION</p>
          <p className={classes.description}>The General Terms and Conditions will be governed by and construed and enforced in
accordance with the laws of the Republic of Ghana. If the respective dispute resulting from the
General Terms or Agreement could not be settled by the negotiations, then the dispute will be
finally solved in Ghana Courts. If any provision of the General Terms is held to be unenforceable,
the parties will substitute for the affected provision an enforceable provision that approximates
the intent and economic effect of the affected provision.</p>
<p className={classes.description} style={{ fontWeight: 'bold',color:'black'}}>NOTICES</p>
          <p className={classes.description}>16.1. You are obliged to immediately notify us of any changes to your contact information.</p>
          <p className={classes.description}>16.2. Any notice required to be given under the General Terms and Agreement shall be
sufficiently given if: (i) delivered personally, (ii) sent by courier with proof of delivery, (iii) sent by
registered mail, (iv) sent by e-mail or (v) made available via the Moto Moto App or Moto Moto
Account. Any notice which is sent or dispatched in accordance with this clause shall be deemed
to have been received: (i) if delivered personally, at the time of delivery to the party; (ii) if
delivered by courier, on the date stated by the courier as being the date on which the envelope containing the notice was delivered to the party; (iii) if sent by registered mail, on the 10th day
after handing the document over to the post office for delivery to the party; (iv) if made available
via the Moto Moto App or Moto Moto Account, or (v) if sent by e-mail, on the day the party
receiving the e-mail confirms receiving the respective e-mail or on the 2nd day following the
dispatch of the e-mail provided that the sender has not received an error notice (notifying that
the e-mail was not delivered to the party) and has sent the e-mail again on the next calendar day
and has not received a similar error notice.</p>
          <p className={classes.description} style={{ fontWeight: 'bold',color:'black'}}>FINAL PROVISIONS</p>
          <p className={classes.description}>If any provision of the General Terms is held to be unenforceable, the parties shall substitute for
the affected provision an enforceable provision that approximates the intent and economic
effect of the affected provision.</p>
          <p className={classes.description}>Date of entry into force of the General Terms and Conditions: 06th February 2022</p>
          <p className={classes.description} style={{ fontWeight: 'bold',color:'black'}}>USER PRIVACY POLICY</p>
          <p className={classes.description}>This privacy statement applies to users of Moto Moto services anywhere in the world
including our Apps, services, any kind of Delivery Services using our call center, delivery, or
any other on-demand services.</p>
          <p className={classes.description}>This policy explains the information Moto Moto collects, how it is used and shared when it
is collected, and the availability of choices you have.</p>
          <p className={classes.description}>Moto Moto collects information about you when you use our mobile application. website
and other online services.</p>
<p className={classes.description}>
            <ul>
              <li>
              We collect information when you create the Moto Moto Account.
              </li>
              <li>
              When you use our services, such as location, usage, and device information.
              </li>
              <li>
              Information from other sources such as partners and third parties that use Moto
Moto APIs.
              </li>
              </ul>
              </p>
          <p className={classes.description}>The following information is also collected by or on behalf of Moto Moto, We Collect
information when you create or update your Moto Moto account.</p>
          <p className={classes.description}>This may include your name, email, phone number, login name and password, address,
profile picture, payment or banking information, (including related payment verification
information), and any other information you choose to provide.</p>
          <p className={classes.description}>When you use Moto Moto we collect the below information.</p>
          <p className={classes.description} style={{ fontWeight: 'bold',color:'black'}}>LOCATION INFORMATION</p>
          <p className={classes.description}>When you use Moto Moto services as a mode of delivery we use your exact or approximate
location as determined by through data such as GPS, wi-fi, and IP. Also, the delivery data
will also be gathered via the Moto Moto Rider App. When the Moto Moto app is running even in the background we may gather your location.</p>
<p className={classes.description} style={{ fontWeight: 'bold',color:'black'}}>USER CONTENT</p>
          <p className={classes.description}>We may collect information about you when you contact our customer support center</p>
          <p className={classes.description} style={{ fontWeight: 'bold',color:'black'}}>CONTACTS</p>
          <p className={classes.description}>If you permit Moto Moto app to access your phone address book on your device through
our permission used by our Moto Moto mobile platform, we may use the contact details to
facilitate better interaction or any other purpose.</p>
          <p className={classes.description} style={{ fontWeight: 'bold',color:'black'}}>TRANSACTION INFORMATION</p>
          <p className={classes.description}>We collect transaction details related to your use of our services, including the type of
services you requested or provided such as delivery information, amounts charged, pick up
and drop locations, distance traveled, and any other related information. And if someone
uses your promo codes, we may associate name with that person.</p>
          <p className={classes.description} style={{ fontWeight: 'bold',color:'black'}}>USAGES INFORMATION</p>
          <p className={classes.description}>We collect information about how you interact with our services, and our website usage,
this includes preferences made, dates visited, pages viewed, app features used, type of
browser, sometimes we collect this information through cookies, pixel tags, or any other
technologies that maintain unique identifiers.</p>
          <p className={classes.description}>Information You Provide Directly to Us. We collect Personal Information you provide directly
to us, Information and transaction information when you use the Moto Moto Services,
including your account information such as name, phone number, email address, and credit
card number.</p>
          <p className={classes.description}>Automatic Data Collection. We may collect certain information automatically through your
use of the Moto Moto Services (including our websites),such as your Internet protocol (IP)
address, Technologies including cookie identifiers and mobile advertising identifiers, mobile
carrier, MAC address, IMEI, and other device identifiers that are automatically assigned to
your device.</p>
<p className={classes.description} style={{ fontWeight: 'bold',color:'black'}}>COMMUNICATION DATA</p>
          <p className={classes.description}>We enable the Rider and Client to communicate via voice or SMS (even by disclosing each
other’s telephone numbers). In facilitating this service, we receive call data such as time
/date/duration/ and SMS messages. Moto Moto may use all these data for customer
support services, including resolving disputes between users and for the safety and security
of the Client as well as the Rider.</p>
<p className={classes.description} style={{ fontWeight: 'bold',color:'black'}}>LOG INFORMATION</p>
          <p className={classes.description}>We you use and interact with our services we collect server logs that may contain IP
address app features, dates times pages viewed and all other system activity, or any thirdparty services you were using before.</p>
          <p className={classes.description} style={{ fontWeight: 'bold',color:'black'}}>PLATFORM PERMISSION</p>
          <p className={classes.description}>IOS and Android platforms will in their unique way prompt you to submit your consent when
the Moto Moto app is installed for the first time on your device, this occurs when
permission is required to access certain types of data in your device.</p>
          <p className={classes.description} style={{ fontWeight: 'bold',color:'black'}}>OTHER WAYS WE COLLECT INFORMATION</p>
          <p className={classes.description}>When you use our taxi app. Riders may use the user ratings after providing services, also
when using our website or other services we may merge all the data gathered.</p>
          <p className={classes.description} style={{ fontWeight: 'bold',color:'black'}}>HOW WE MAY USE THE INFORMATION GATHERED</p>
          <p className={classes.description}>We may use data gathered from you to maintain and improve our services and to facilitate
payments and issue receipts, and avail you with enhanced features.</p>
<p className={classes.description}>
            <ul>
              <li>
              To communicate with you to inform you of new services, offers, or news, or any kind
of communication that might be of interest to you.
              </li>
              <li>
              To enhance our internal operations and data analysis.
              </li>
              <li>
              To facilitate communication with you and the Rider or any other delivery patrons or
any of your contacts or to calculate the Estimated time of arrival.
              </li>
              <li>
              To send advertisements and other information that might benefit you.
              </li>
              <li>
              Sharing of your information.
              </li>
              <li>
              We may share your details with the taxi service providing Rider (Moto Moto service
providers).
              </li>
              <li>
              Your Name /Tel /Photo- (If available) user ratings/ pick up and drop off locations to
relevant Riders.
              </li>
              <li>
              To any other service provider that you may have asked via Moto Moto to provide a
service.
              </li>
              <li>
              With our marketing team for promotions.
              </li>
              <li>
              In the event data is required by a relevant authority, Government officials, for any
matter that.
              </li>
              <li>
              Comes under the applicable laws, or to protect the rights of Moto Moto or others.
              </li>
              <li>
              In the event of a business merger, sale of a business, or sale of company assets. 
              </li>
              <li>
              In a list of Moto Moto registered users that cannot be used to identify you
individually.
              </li>
              </ul>
              </p>
          <p className={classes.description} style={{ fontWeight: 'bold',color:'black'}}>SUMMARY</p>
          <p className={classes.description}>Moto Moto collects and uses the information to enable a reliable and convenient delivery
and other products and services. We also use the information we collect</p>
<p className={classes.description}>
            <ul>
           <li>
              To enhance the safety and security of our users and services.
              </li>
              <li>
              For research and development.
              </li>
              <li>
              For customer support.
              </li>
              <li>
              To enable communication between Clients, Riders, and Users.
              </li>
              <li>
              In connection with legal proceedings.
              </li>
              <li>
              To provide promotions.
              </li>
              </ul>
              </p>
              <p className={classes.description} style={{ fontWeight: 'bold',color:'black'}}>COKIES & THIRD-PARTY TECHNOLOGY</p>
          <p className={classes.description}>Moto Moto does not use cookies and other identification technologies on your apps.,
websites, emails, and online ads.</p>
          <p className={classes.description} style={{ fontWeight: 'bold',color:'black'}}>USER's REQUEST FOR DATA DELETION</p>
          <p className={classes.description}>In accordance with applicable law, users may have the right to:
          <ul>
           <li>
           Request personal information data be deleted from Moto Moto’s records.
              </li>
              <li>
              Obtain access of the user’s personal information.
              </li>
              <li>
              Receive an electric copy of the user’s information that has been provided to
Moto Moto.
              </li>
              <li>
              Restrict Moto Moto’s use of personal information data that was submitted.
              </li>
              </ul>
          </p>
          <p className={classes.description}>To submit a request please look for the contact below</p>
          <p className={classes.description}>All requests will be processed accordance with applicable laws.</p>
          <p className={classes.description} style={{ fontWeight: 'bold',color:'black'}}>SECURITY OF DATA</p>
          <p className={classes.description}>Moto Moto places priority on securing user’s personal information data. Moto Moto cannot
guarantee 100% data security. In the event of an unauthorized data breach, Moto Moto
cannot ensure security of any information the user has provided. Moto Moto does not
accept liability for any unauthorized disclosures.</p>
          <p className={classes.description} style={{ fontWeight: 'bold',color:'black'}}>CONTACT US</p>
          <p className={classes.description}>If you have any questions about our Terms statement please use any of the below methods
to contact us.</p>
          <p className={classes.description}>Email- <a>support@motomoto.taxi</a></p>
          <p className={classes.description}>Or write to us on – GM-172-1622 CE754 Kish St, Oyarifa, Ghana</p>
       































          {/* <p className={classes.description}>
            <ul>
              <li>
                To communicate with you to inform you of new services, offers, or news, or
                any kind of communication that might be of interest to you.
              </li>
              <li>
                To enhance our internal operations and data analysis.
              </li>
              <li>
                To facilitate communication with you and the Rider or any other delivery
                patrons or any of your contacts or to calculate the Estimated time of arrival.
              </li>
              <li>
                To send advertisements and other information that might benefit you.
              </li>
              <li>
                Sharing of your information.
              </li>
              <li>
                We may share your details with the taxi service providing Rider (Moto Moto
                service providers).
              </li>
              <li>
                Your Name /Tel /Photo- (If available) user ratings/ pick up and drop off
                locations to relevant Riders.
              </li>
              <li>
                To any other service provider that you may have asked via Moto Moto to
                provide a service.
              </li>
              <li>
                With our marketing team for promotions.
              </li>
              <li>
                In the event data is required by a relevant authority, Government officials, for
                any matter that
              </li>
              <li>
                Comes under the applicable law of the country, or to protect the rights of Moto
                Moto or others.
              </li>
              <li>
                In the event of a business merger, sale of a business, or sale of company
                assets.
              </li>
              <li>
                In a list of Moto Moto registered users that cannot be used to identify you
                individually.
              </li>
            </ul>
          </p>
          <p className={classes.description} style={{ fontWeight: '500', color: 'black', textUnderlinePosition: 'under', textDecorationLine: 'underline' }}>Summary</p>
          <p className={classes.description}>Moto Moto collects and uses the information to enable a reliable and convenient
            delivery and other products and services. We also use the information we collect</p>
          <p className={classes.description}>
            <ul>
              <li>
                To enhance the safety and security of our users and services
              </li>
              <li>
                For research and development
              </li>
              <li>
                For customer support
              </li>
              <li>
                To enable communication between Clients, Riders, and Users.
              </li>
              <li>
                In connection with legal proceedings
              </li>
              <li>
                To provide promotions.
              </li>
            </ul>
          </p>
          <p className={classes.description} style={{ fontWeight: '500', color: 'black', textUnderlinePosition: 'under', textDecorationLine: 'underline' }}>Cookies and third-party Technologies</p>
          <p className={classes.description}>Moto Moto and its partners use cookies and other identification technologies on
            your apps., websites, emails, and online ads.</p>
          <p className={classes.description}>Cookies are small text files that are stored on your web browser or device by
            websites, apps, online media, and advertisements Moto Moto may use these cookies
            for the purposes such as</p>
          <p className={classes.description}>
            <ul>
              <li>
                Remembering user preferences
              </li>
              <li>
                Authenticating a user\
              </li>
              <li>
                Analyzing site traffic etc.
              </li>
              <li>
                To understand the general behavior and interest of people
              </li>
              <li>
                To track the performance of advertisements
              </li>
            </ul>
          </p>
          <p className={classes.description}>The above may use cookies, web beacons, SDKs, and any other technologies to
            identify your device when you visit our website or use our services.
          </p>
          <p className={classes.description}>You can choose to accept or reject cookies, and most browsers accept cookies
            automatically, but you have the option of modifying your browser to prompt you of a
            cookie or to decline them, but this may prevent you from making full use of our
            website.
          </p>
          <p className={classes.description} style={{ fontWeight: '500', color: 'black', textUnderlinePosition: 'under', textDecorationLine: 'underline' }}>Contact Us</p>
          <p className={classes.description}>If you have any questions about our Terms statement please use any of the below
            methods to contact us.</p>
          <p className={classes.description}>Email- <a>support@motomoto.taxi</a></p>
          <p className={classes.description} style={{ fontWeight: '500' }}>Or write to us on – GM 109-3031 Shalom Estates Oyarifa, Ghana</p>
          <br /> */}

        </div>
      </div>

      <Footer />
    </div>
  );
}