export const FirebaseConfig = {
	"projectId": "motomoto-a2c96",
	"appId": "1:340845535964:web:4f3ab23d456c7c19528a19",
	"databaseURL": "https://motomoto-a2c96-default-rtdb.firebaseio.com",
	"storageBucket": "motomoto-a2c96.appspot.com",
	"locationId": "us-central",
	"apiKey": "AIzaSyBp-u8fCaS9GVb4wsFgQ_rG6ur3o7MFpXc",
	"authDomain": "motomoto-a2c96.firebaseapp.com",
	"messagingSenderId": "340845535964",
	"measurementId": "G-HWTHJ2RN1G"
};