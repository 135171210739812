import React, { useState, useEffect, useContext, useRef } from 'react';
import MaterialTable from 'material-table';
import CircularLoading from "../components/CircularLoading";
import { useSelector, useDispatch } from "react-redux";
import ConfirmationDialogRaw from '../components/ConfirmationDialogRaw';
import {
  Grid,
  Typography,
  Modal,
  Button,
  TextField,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup
} from '@material-ui/core';
import { FirebaseContext } from 'common';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import UsersCombo from '../components/UsersCombo';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from "react-i18next";
import moment from 'moment/min/moment-with-locales';
import CancelIcon from '@material-ui/icons/Cancel';
import PaymentIcon from '@material-ui/icons/Payment';
import AlertDialog from '../components/AlertDialog';


const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    padding: theme.spacing(1),
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    width: 300,
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
}));

const BookingHistory = (props) => {
  const { api, appcat } = useContext(FirebaseContext);
  const { t, i18n } = useTranslation();
  const isRTL = i18n.dir();
  const {
    cancelBooking,
    updateBooking,
    RequestPushMsg
  } = api;
  const dispatch = useDispatch();
  const auth = useSelector(state => state.auth);
  const userdata = useSelector(state => state.usersdata);
  const settings = useSelector(state => state.settingsdata.settings);
  const [role, setRole] = useState(null);
  const [paymentModalStatus, setPaymentModalStatus] = useState(false);
  const [data, setData] = useState([]);
  const [openConfirm, setOpenConfirm] = useState(false);
  const [selectedBooking, setSelectedBooking] = useState();
  const bookinglistdata = useSelector(state => state.bookinglistdata);
  const [users, setUsers] = useState(null);
  const [userCombo, setUserCombo] = useState(null);
  const rootRef = useRef(null);
  const [open, setOpen] = useState(false);
  const [rowIndex, setRowIndex] = useState();
  const [mobile, setMobile] = useState("");
  const [otpModalStatus,setOtpModalStatus] = useState(false);
  const [commonAlert, setCommonAlert] = useState({ open: false, msg: '' });
  const [otp,setOtp] = useState("");
  const classes = useStyles();
  const providers = [
    { 'code': 'mtn', 'name': 'MTN' },
    { 'code': 'vod', 'name': 'Vodafone' },
    { 'code': 'tgo', 'name': 'Airtel/Tigo' }
  ]
  const [selectedProvider, setSelectedProvider] = useState(providers[0]);
  const [selectedProviderIndex, setSelectedProviderIndex] = useState(0);
  const [otpRef,setOtpRef] = useState({reference: "",  message: ""});
  const columns = [
    { title: t('booking_id'), field: 'refId', cellStyle: isRTL=== 'rtl' ? {paddingRight:220}:{paddingLeft:220}, headerStyle: isRTL=== 'rtl' ?{paddingRight:220}:{paddingLeft:220}, },
    { title: t('booking_date'), field: 'tripdate', render: rowData => rowData.tripdate ? moment(rowData.tripdate).format('lll') : null, cellStyle: { textAlign: isRTL === 'rtl' ? 'right' : 'left' } },
    { title: t('car_type'), field: 'carType', cellStyle: { textAlign: isRTL === 'rtl' ? 'right' : 'left' } },
    { title: t('customer_name'), field: 'customer_name', cellStyle: { textAlign: isRTL === 'rtl' ? 'right' : 'left' } },
    { title: t('pickup_address'), field: 'pickupAddress', cellStyle: { textAlign: isRTL === 'rtl' ? 'right' : 'left' } },
    { title: t('drop_address'), field: 'dropAddress', cellStyle: { textAlign: isRTL === 'rtl' ? 'right' : 'left' } },
    { title: t('assign_driver'), field: 'driver_name', cellStyle: { textAlign: isRTL === 'rtl' ? 'right' : 'left' } },
    { title: t('deliveryPerson'), field: 'deliveryPerson', hidden: appcat === 'taxi' ? true : false, cellStyle: { textAlign: isRTL === 'rtl' ? 'right' : 'left' } },
    { title: t('deliveryPersonPhone'), field: 'deliveryPersonPhone', hidden: appcat === 'taxi' ? true : false, cellStyle: { textAlign: isRTL === 'rtl' ? 'right' : 'left' } },
    { title: t('pickUpInstructions'), field: 'pickUpInstructions', hidden: appcat === 'taxi' ? true : false, cellStyle: { textAlign: isRTL === 'rtl' ? 'right' : 'left' } },
    { title: t('deliveryInstructions'), field: 'deliveryInstructions', hidden: appcat === 'taxi' ? true : false, cellStyle: { textAlign: isRTL === 'rtl' ? 'right' : 'left' } },
    { title: t('parcel_types'), render: rowData => <span>{rowData.parcelTypeSelected ? rowData.parcelTypeSelected.description + " (" + rowData.parcelTypeSelected.amount + ")" : ""}</span>, hidden: appcat === 'taxi' ? true : false, cellStyle: { textAlign: isRTL === 'rtl' ? 'right' : 'left' } },
    { title: t('parcel_option'), render: rowData => <span>{rowData.optionSelected ? rowData.optionSelected.description + " (" + rowData.optionSelected.amount + ")" : ""}</span>, hidden: appcat === 'taxi' ? true : false, cellStyle: { textAlign: isRTL === 'rtl' ? 'right' : 'left' } },
    { title: t('booking_status'), field: 'status', render: rowData => <span>{t(rowData.status)}</span>, cellStyle: { textAlign: isRTL === 'rtl' ? 'right' : 'left' } },
    { title: t('take_pickup_image'), field: 'pickup_image', render: rowData => rowData.pickup_image ? <img alt='Pick Up' src={rowData.pickup_image} style={{ width: 150 }} /> : null, editable: 'never', hidden: appcat === 'taxi' ? true : false },
    { title: t('take_deliver_image'), field: 'deliver_image', render: rowData => rowData.deliver_image ? <img alt='Deliver' src={rowData.deliver_image} style={{ width: 150 }} /> : null, editable: 'never', hidden: appcat === 'taxi' ? true : false },
    { title: t('cancellation_reason'), field: 'reason', cellStyle: { textAlign: isRTL === 'rtl' ? 'right' : 'left' } },
    { title: t('otp'), field: 'otp', cellStyle: { textAlign: isRTL === 'rtl' ? 'right' : 'left' } },
    { title: t('trip_cost'), field: 'trip_cost', cellStyle: { textAlign: isRTL === 'rtl' ? 'right' : 'left' } },
    { title: t('trip_start_time'), field: 'trip_start_time', cellStyle: { textAlign: isRTL === 'rtl' ? 'right' : 'left' } },
    { title: t('trip_end_time'), field: 'trip_end_time', cellStyle: { textAlign: isRTL === 'rtl' ? 'right' : 'left' } },
    { title: t('total_time'), field: 'total_trip_time', cellStyle: { textAlign: isRTL === 'rtl' ? 'right' : 'left' } },
    { title: t('distance'), field: 'distance', cellStyle: { textAlign: isRTL === 'rtl' ? 'right' : 'left' } },
    { title: t('vehicle_no'), field: 'vehicle_number', cellStyle: { textAlign: isRTL === 'rtl' ? 'right' : 'left' } },
    { title: t('trip_cost_driver_share'), hidden: role === 'rider' ? true : false, field: 'driver_share', cellStyle: { textAlign: isRTL === 'rtl' ? 'right' : 'left' } },
    { title: t('convenience_fee'), hidden: role === 'rider' ? true : false, field: 'convenience_fees', cellStyle: { textAlign: isRTL === 'rtl' ? 'right' : 'left' } },
    { title: t('discount_ammount'), field: 'discount', cellStyle: { textAlign: isRTL === 'rtl' ? 'right' : 'left' } },
    { title: t('Customer_paid'), field: 'customer_paid', cellStyle: { textAlign: isRTL === 'rtl' ? 'right' : 'left' } },
    { title: t('payment_mode'), field: 'payment_mode', cellStyle: { textAlign: isRTL === 'rtl' ? 'right' : 'left' } },
    { title: t('payment_gateway'), field: 'gateway', cellStyle: { textAlign: isRTL === 'rtl' ? 'right' : 'left' } },
    { title: t('cash_payment_amount'), field: 'cashPaymentAmount', cellStyle: { textAlign: isRTL === 'rtl' ? 'right' : 'left' } },
    { title: t('card_payment_amount'), field: 'cardPaymentAmount', cellStyle: { textAlign: isRTL === 'rtl' ? 'right' : 'left' } },
    { title: t('wallet_payment_amount'), field: 'usedWalletMoney', cellStyle: { textAlign: isRTL === 'rtl' ? 'right' : 'left' } }
  ];

  useEffect(() => {
    if (bookinglistdata.bookings) {
      setData(bookinglistdata.bookings);
    } else {
      setData([]);
    }
  }, [bookinglistdata.bookings]);

  useEffect(() => {
    if (auth.info && auth.info.profile) {
      setRole(auth.info.profile.usertype);
    }
  }, [auth.info]);

  useEffect(() => {
    if (userdata.users) {
      let arr = [];
      for (let i = 0; i < userdata.users.length; i++) {
        let user = userdata.users[i];
        if (user.usertype === 'driver') {
          arr.push({
            'firstName': user.firstName,
            'lastName': user.lastName,
            'mobile': user.mobile,
            'email': user.email,
            'uid': user.id,
            'desc': user.firstName + ' ' + user.lastName + ' (' + (settings.AllowCriticalEditsAdmin ? user.mobile : "Hidden") + ') ' + (settings.AllowCriticalEditsAdmin ? user.email : "Hidden"),
            'pushToken': user.pushToken,
            'carType': user.carType
          });
        }
      }
      setUsers(arr);
    }
  }, [userdata.users, settings.AllowCriticalEditsAdmin]);

  const assignDriver = () => {
    let booking = data[rowIndex];
    if (booking['requestedDrivers']) {
      booking['requestedDrivers'][userCombo.uid] = true;
    } else {
      booking['requestedDrivers'] = {};
      booking['requestedDrivers'][userCombo.uid] = true;
    }
    dispatch(updateBooking(booking));
    RequestPushMsg(
      userCombo.pushToken,
      {
        title: t('notification_title'),
        msg: t('new_booking_notification'),
        screen: 'DriverTrips'
      }
    );
    setUserCombo(null);
    handleClose();
    setCommonAlert({ open: true, msg: "Driver assigned successfully and notified." });
  }

  const onConfirmClose = (value) => {
    if (value) {
      dispatch(cancelBooking({
        reason: value,
        booking: selectedBooking,
        cancelledBy: role
      }));
    }
    setOpenConfirm(false);
  }

  const handleChange = (e) => {
    if (e.target.name === 'selectedProviderIndex') {
      setSelectedProviderIndex(parseInt(e.target.value));
      setSelectedProvider(providers[parseInt(e.target.value)]);
    }
    if (e.target.name === 'mobile') {
      setMobile(e.target.value);
    }
    if (e.target.name === 'otp') {
      setOtp(e.target.value);
    }
  };

  const handleClose = () => {
    setOpen(false);
  }

  const handleSubmitOtpCall = (e) => {
    e.preventDefault();
    if (otp.length > 0) {
      const otpJson = {
        "otp": otp,
        "reference": otpRef.reference
      };
      fetch(`https://us-central1-motomoto-a2c96.cloudfunctions.net/submitotp`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(otpJson)
      }).then(response => {
        return response.json();
      })
        .then(json => {
          handleOtpModalClose();
          if (json.status === true && json.data.status === "success") {
            setOtpRef({reference: "",  message: ""});
            setCommonAlert({ open: true, msg: t('success_payment') });
          }
          else if (json.status === true && json.data.status === "pending") {
            setCommonAlert({ open: true, msg: json.data.message });
          } 
          else if (json.status === false && json.data) {
            setCommonAlert({ open: true, msg: json.message + " - " + json.data.message });
          } 
          else {
            setOtpRef({reference: "",  message: ""});
            setCommonAlert({ open: true, msg: t('payment_fail')});
          }
        }).catch(error => {
          handleOtpModalClose();
          console.log(error);
          setCommonAlert({ open: true, msg: "Fetch Call Error" });
        })
    } else {
      setCommonAlert({ open: true, msg: t('no_details_error') });
    }
  }


  const handleCallChargeApi = (e) => {
    e.preventDefault();
    if (mobile.length > 5) {
      const payJson = {
        "email": auth.info.profile.email,
        "phone": mobile,
        "provider": selectedProvider.code,
        "order_id": selectedBooking.id,
        "amount": selectedBooking.payableAmount ? selectedBooking.payableAmount : selectedBooking.trip_cost
      };
      fetch(`https://us-central1-motomoto-a2c96.cloudfunctions.net/chargeapi`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(payJson)
      }).then(response => {
        return response.json();
      })
        .then(json => {
          console.log(json);
          handlePaymentModalClose();
          if (json.status === true && json.data.status === "success") {
            setCommonAlert({ open: true, msg: t('success_payment') });
          }
          else if (json.status === true && json.data.status === "pay_offline") {
            setCommonAlert({ open: true, msg: json.data.display_text });
          }
          else if (json.status === true && json.data.status === "send_otp") {
            setOtpRef({
              reference: json.data.reference,
              message: json.data.display_text
            });
            setOtpModalStatus(true);
          } 
          else if (json.status === true && json.data.status === "pending") {
            setCommonAlert({ open: true, msg: json.data.message });
          } 
          else if (json.status === false && json.data) {
            setCommonAlert({ open: true, msg: json.message + " - " + json.data.message });
          } 
          else {
            setOtpRef({reference: "",  message: ""});
            setCommonAlert({ open: true, msg: t('payment_fail')});
          }
        }).catch(error => {
          handlePaymentModalClose();
          console.log(error);
          setCommonAlert({ open: true, msg: "Fetch Call Error" });
        })
    } else {
      setCommonAlert({ open: true, msg: t('no_details_error') });
    }
  }

  const handlePaymentModalClose = (e) => {
    setMobile("");
    setPaymentModalStatus(false);
  }

  const handleOtpModalClose = (e) => {
    setOtp("");
    setOtpModalStatus(false);
  }

  const handleCommonAlertClose = (e) => {
    e.preventDefault();
    setCommonAlert({ open: false, msg: '' })
  };

  const processPayment = (rowData) => {
    const curBooking = rowData;
    const paymentPacket = {
      appcat: appcat,
      payment_mode: 'card',
      customer_paid: (parseFloat(curBooking.trip_cost) - parseFloat(curBooking.discount)).toFixed(2),
      cardPaymentAmount: curBooking.payableAmount ? curBooking.payableAmount : curBooking.trip_cost,
      discount: curBooking.discount ? curBooking.discount : 0,
      usedWalletMoney: curBooking.usedWalletMoney ? curBooking.usedWalletMoney : 0,
      cashPaymentAmount: 0,
      promo_applied: curBooking.promo_applied ? curBooking.promo_applied : false,
      promo_details: curBooking.promo_details ? curBooking.promo_details : null,
      payableAmount: curBooking.payableAmount ? curBooking.payableAmount : curBooking.trip_cost
    };
    curBooking.paymentPacket = paymentPacket;
    dispatch(updateBooking(curBooking));
    setSelectedBooking(curBooking);
  }

  return (
    bookinglistdata.loading ? <CircularLoading /> :
      <div>
        <MaterialTable
          title={t('booking_title')}
          columns={columns}
          data={data}
          options={{
            exportButton: settings.AllowCriticalEditsAdmin,
            sorting: true
          }}
          actions={[
            rowData => ({
              icon: () => <div style={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap' }}>
                <CancelIcon />
                <Typography variant="subtitle2">{t('cancel_booking')}</Typography>
              </div>,
              disabled: rowData.status === 'NEW' || rowData.status === 'ACCEPTED' || rowData.status === 'PAYMENT_PENDING' ? false : true,
              onClick: (event, rowData) => {
                if (settings.AllowCriticalEditsAdmin && (role === 'rider' || role === 'admin')) {
                  if (rowData.status === 'NEW' || rowData.status === 'ACCEPTED') {
                    setSelectedBooking(rowData);
                    setOpenConfirm(true);
                  } else {
                    setTimeout(() => {
                      dispatch(cancelBooking({
                        reason: t('cancelled_incomplete_booking'),
                        booking: rowData
                      }));
                    }, 1500);
                  }
                } else {
                  alert(t('demo_mode'));
                }
              }
            }),
            rowData => ({
              icon: () => <div style={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap' }}>
                <PersonAddIcon />
                <Typography variant="subtitle2">{t('assign_driver')}</Typography>
              </div>,
              disabled: (rowData.status === 'NEW' && role === 'admin' && settings.autoDispatch === false) ? false : true,
              onClick: (event, rowData) => {
                setOpen(true)
                setRowIndex(rowData.tableData.id);
              }
            }),
            rowData => ({
              icon: () => <div style={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap' }}>
                <PaymentIcon />
                <Typography variant="subtitle2">{t('paynow_button')}</Typography>
              </div>,
              disabled: (rowData.status === 'PENDING' && role === 'rider') ? false : true,
              onClick: (event, rowData) => {
                processPayment(rowData);
                setPaymentModalStatus(true);
              }
            })
          ]}
        />
         <Modal
            disablePortal
            disableEnforceFocus
            disableAutoFocus
            open={otpModalStatus}
            onClose={handleOtpModalClose}
            className={classes.modal}
            container={() => rootRef.current}
          >
            <Grid container spacing={2} className={classes.paper}>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <FormControl component="fieldset">
                <FormLabel component="legend" style={{ textAlign: isRTL === 'rtl' ? 'right' : 'left' }}>{otpRef.message}</FormLabel>
                  <TextField
                    variant="outlined"
                    margin="normal"
                    fullWidth
                    id="otp"
                    label={t('otp')}
                    name="otp"
                    autoComplete="otp"
                    onChange={handleChange}
                    value={otp}
                    className={isRTL === 'rtl' ? classes.inputRtl : null}
                    style={{ direction: isRTL === 'rtl' ? 'rtl' : 'ltr' }}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12} style={{ direction: isRTL === 'rtl' ? 'rtl' : 'ltr' }}>
                <Button onClick={handleOtpModalClose} variant="contained" color="primary">
                  {t('cancel')}
                </Button>
                <Button variant="contained" color="primary" type="submit" style={isRTL === 'rtl' ? { marginRight: 10 } : { marginLeft: 10 }} onClick={handleSubmitOtpCall}>
                  {t('paynow_button')}
                </Button>
              </Grid>
            </Grid>
          </Modal>
        {selectedBooking && selectedBooking.status === 'PENDING' && role === 'rider' ?
          <Modal
            disablePortal
            disableEnforceFocus
            disableAutoFocus
            open={paymentModalStatus}
            onClose={handlePaymentModalClose}
            className={classes.modal}
            container={() => rootRef.current}
          >
            <Grid container spacing={2} className={classes.paper}>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <FormControl component="fieldset">
                  <FormLabel component="legend" style={{ textAlign: isRTL === 'rtl' ? 'right' : 'left' }}>{t('payment')}</FormLabel>
                  <RadioGroup name="selectedProviderIndex" value={selectedProviderIndex} onChange={handleChange}>
                    {providers.map((provider, index) =>
                      <FormControlLabel key={provider.code} value={index} control={<Radio />} label={provider.name} />
                    )}
                  </RadioGroup>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <FormControl component="fieldset">
                  <TextField
                    variant="outlined"
                    margin="normal"
                    fullWidth
                    id="mobile"
                    label={t('mobile')}
                    name="mobile"
                    autoComplete="mobile"
                    onChange={handleChange}
                    value={mobile}
                    className={isRTL === 'rtl' ? classes.inputRtl : null}
                    style={{ direction: isRTL === 'rtl' ? 'rtl' : 'ltr' }}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12} style={{ direction: isRTL === 'rtl' ? 'rtl' : 'ltr' }}>
                <Button onClick={handlePaymentModalClose} variant="contained" color="primary">
                  {t('cancel')}
                </Button>
                <Button variant="contained" color="primary" type="submit" style={isRTL === 'rtl' ? { marginRight: 10 } : { marginLeft: 10 }} onClick={handleCallChargeApi}>
                  {t('paynow_button')}
                </Button>
              </Grid>
            </Grid>
          </Modal>
          : null}
        <ConfirmationDialogRaw
          open={openConfirm}
          onClose={onConfirmClose}
          value={''}
        />
        {users && data && rowIndex >= 0 ?
          <Modal
            disablePortal
            disableEnforceFocus
            disableAutoFocus
            onClose={handleClose}
            open={open}
            className={classes.modal}
            container={() => rootRef.current}
          >
            <div className={classes.paper}>
              <Grid container spacing={2} >
                <Grid item xs={12}>
                  <Typography component="h1" variant="h5" className={classes.title} style={{ textAlign: isRTL === 'rtl' ? 'right' : 'left' }}>
                    {t('select_driver')}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <UsersCombo
                    className={classes.items}
                    placeholder={t('select_user')}
                    users={users.filter(usr => usr.carType === data[rowIndex].carType)}
                    value={userCombo}
                    onChange={(event, newValue) => {
                      setUserCombo(newValue);
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12} style={{ direction: isRTL === 'rtl' ? 'rtl' : 'ltr', marginLeft: isRTL === 'rtl' ? '65%' : 0 }}>
                  <Button onClick={handleClose} variant="contained" color="primary">
                    {t('cancel')}
                  </Button>
                  <Button onClick={assignDriver} variant="contained" color="primary" style={isRTL === 'rtl' ? { marginRight: 10 } : { marginLeft: 10 }}>
                    {t('assign')}
                  </Button>
                </Grid>
              </Grid>
            </div>
          </Modal>
          : null}
        <AlertDialog open={commonAlert.open} onClose={handleCommonAlertClose}>{commonAlert.msg}</AlertDialog>
      </div>

  );
}

export default BookingHistory;
